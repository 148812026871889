
import 'intl-tel-input/build/css/intlTelInput.css'
import intlTelInput from 'intl-tel-input'
import { parsePhoneNumber, getExample } from 'awesome-phonenumber'

export default {
  data: -> {
    country: {}
    number: ''
    number_placeholder: null
    valid: true
    validation_text: null
    rules: {
      required: (v) =>
        if @required and not !!v then @$t('input_phone_validate_required') else true
      telephone: (v) =>
        !!v && parsePhoneNumber(v, { regionCode: @country.symbol }).valid || @$t('input_phone_validate_format')
    }
  }

  mounted: ->
    if !@value.length
      country = @get_country_by_symbol(@default_country)
      @country = @build_country_option(country)
    else
      region_code = parsePhoneNumber(@value).regionCode
      country = @get_country_by_symbol(region_code)
      @country = @build_country_option(country)
      phone = parsePhoneNumber(@value, { regionCode: region_code })
      @$nextTick => @number = phone.number.significant

  watch: {
    country: ->
      @number = null
      @number_placeholder = getExample( @country.symbol, 'mobile' ).number.significant
      @$refs.telephone_input.focus()

    number: (v) ->
      @$emit 'input', @i164_number()
  }

  computed: {
    country_options: ->
      (@build_country_option(country) for country in window.intlTelInputGlobals.getCountryData())
  }

  props: {
    default_country: { type: String, default: 'za' }
    error: { type: String, default: null}
    required: { type: Boolean, default: true }
    value: { type: String, default: '' }
  }

  methods: {
    build_country_option: (country) ->
      {
        name: country.name,
        symbol: country.iso2,
        dial_code: country.dialCode
      }

    flag_class: (country_symbol) -> "iti__flag iti__#{country_symbol.toLowerCase()}"

    get_country_by_symbol: (iso2) ->
      window.intlTelInputGlobals.getCountryData().find (country) => country.iso2.toLowerCase() == iso2.toLowerCase()

    i164_number: ->
      if typeof @number == 'string'
        validation = parsePhoneNumber(@number, { regionCode: @country.symbol })
        if validation.valid then validation.number.e164 else ''
      else ''
  }
}
